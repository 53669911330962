import React from 'react';
import styled from 'styled-components';
import { redDark, white } from '../utils/colors';

const Timeline: React.FC = ({ children }) => {
  return <TimelineContainer>{children}</TimelineContainer>;
};

export default Timeline;

const timelineGap = 20;
const timelineBulletRadius = 8;
const timelineWidth = 2;

const TimelineContainer = styled.ul`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  padding: 0 ${timelineGap}px;
  &&,
  & p {
    text-align: left;
  }
  /* timeline */
  border-left: 2px solid ${redDark};
  list-style: none;
  & li {
    position: relative;
    max-width: 100%;
    flex-shrink: 0;
    margin: 15px 0;
    /* timeline bullets */
    &:before {
      position: absolute;
      top: 12px;
      left: -${timelineGap + (timelineBulletRadius + timelineWidth) / 2}px;
      width: ${timelineBulletRadius}px;
      height: ${timelineBulletRadius}px;
      border: 2px solid ${redDark};
      background-color: ${white};
      border-radius: 50%;

      content: ' ';
    }
  }
`;
