export interface AbihirschMeta {
  title: string;
  subtitle: string;
  matchingFiles: RegExp;
}

export const abihirschMetas: AbihirschMeta[] = [
  {
    title: '2020',
    subtitle: 'Black Lives Matter',
    matchingFiles: /2020-.*/,
  },
  {
    title: '2019',
    subtitle: 'Alles Nullen',
    matchingFiles: /2019-.*/,
  },
  {
    title: '2018',
    subtitle: 'Schrödingers Hirsch',
    matchingFiles: /2018-.*/,
  },
  {
    title: '2017',
    subtitle: 'ABIn den Süden',
    matchingFiles: /2017-.*/,
  },
  {
    title: '2016',
    subtitle: 'Astronaut',
    matchingFiles: /2016-.*/,
  },
  {
    title: '2015',
    subtitle: 'Warnung vor dem Hörsch',
    matchingFiles: /2015-.*/,
  },
  {
    title: '2014',
    subtitle: 'Wir sind die Weltmeister',
    matchingFiles: /2014-.*/,
  },
  {
    title: '2013',
    subtitle: 'Eine Hand wäscht die Andere',
    matchingFiles: /2013-.*/,
  },
  {
    title: '2012',
    subtitle: 'Ballermann',
    matchingFiles: /2012-.*/,
  },
  {
    title: '2011',
    subtitle: 'Alles war aus Gold',
    matchingFiles: /2011-.*/,
  },
  {
    title: '2010',
    subtitle: 'Die Wilde 13',
    matchingFiles: /2010-.*/,
  },
  {
    title: '2009',
    subtitle: 'Yes we can!',
    matchingFiles: /2009-.*/,
  },
  {
    title: '2008',
    subtitle: 'The Oscar goes to...',
    matchingFiles: /2008-.*/,
  },
  {
    title: '2007',
    subtitle: 'Abidas',
    matchingFiles: /2007-.*/,
  },
  {
    title: '2006',
    subtitle: 'Abinson Crusoe',
    matchingFiles: /2006-.*/,
  },
  {
    title: '2005',
    subtitle: 'Fertig zum Versand!',
    matchingFiles: /2005-.*/,
  },
  {
    title: '2004',
    subtitle: 'Batman',
    matchingFiles: /2004-.*/,
  },
  {
    title: '2003',
    subtitle: 'Ein Kamel durchs Nadelöhr',
    matchingFiles: /2003-.*/,
  },
  {
    title: '2002',
    subtitle: 'Jimi Hendrix',
    matchingFiles: /2002-.*/,
  },
  {
    title: '2001',
    subtitle: 'Wahnsinn',
    matchingFiles: /2001-.*/,
  },
  {
    title: '2000',
    subtitle: 'Machs gut, Onni!',
    matchingFiles: /2000-.*/,
  },
  {
    title: '2000 - Hirsch in Kur',
    subtitle: 'Hirsch in Kur',
    matchingFiles: /2000_1-.*/,
  },
  {
    title: '1999',
    subtitle: 'Nichts muss so sein!',
    matchingFiles: /1999-.*/,
  },
  {
    title: '1998',
    subtitle: 'Die neue A(bi)-Klasse',
    matchingFiles: /1998-.*/,
  },
  {
    title: '1997',
    subtitle: 'Der Hirsch ist tot',
    matchingFiles: /1997-.*/,
  },
  {
    title: '1996',
    subtitle: 'Keudelwahnsinn',
    matchingFiles: /1996-.*/,
  },
  {
    title: '1995',
    subtitle: 'Der geflügelte Hirsch',
    matchingFiles: /1995-.*/,
  },
  {
    title: '1994',
    subtitle: 'Reif für die Insel',
    matchingFiles: /1994-.*/,
  },
  {
    title: '1993',
    subtitle: 'Unkontrollierte Abschüsse',
    matchingFiles: /1993-.*/,
  },
  {
    title: '1991',
    subtitle: 'Time for Holiday',
    matchingFiles: /1991-.*/,
  },
  {
    title: '1989',
    subtitle: 'Bücherklau',
    matchingFiles: /1989-.*/,
  },
  {
    title: '1988',
    subtitle: 'Null Problemo',
    matchingFiles: /1988-.*/,
  },
  {
    title: '1987',
    subtitle: 'Decke auf den Kopf',
    matchingFiles: /1987-.*/,
  },
  {
    title: '1986',
    subtitle: 'Zucht und Ordnung',
    matchingFiles: /1986-.*/,
  },
  {
    title: '1985',
    subtitle: 'Hirschbörger',
    matchingFiles: /1985-.*/,
  },
  {
    title: '1983',
    subtitle: 'Watching You!',
    matchingFiles: /1983-.*/,
  },
  {
    title: '1982',
    subtitle: 'Zum fröhlichen Theo',
    matchingFiles: /1982-.*/,
  },
  {
    title: '1981',
    subtitle: 'Klapperstorch',
    matchingFiles: /1981-.*/,
  },
  {
    title: '1980',
    subtitle: 'Brautpaar',
    matchingFiles: /1980-.*/,
  },
  {
    title: '1979',
    subtitle: 'Elefant',
    matchingFiles: /1979-.*/,
  },
  {
    title: '1953',
    subtitle: 'Sportabitur',
    matchingFiles: /1953-.*/,
  },
  {
    title: 'Historischer Hirsch',
    subtitle: '',
    matchingFiles: /0000-.*/,
  },
];

export default abihirschMetas;
