import React, { useState, useEffect } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { Button } from 'grommet';
import { Previous, Next, Close } from 'grommet-icons';
import { Swipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import { HotKeys } from 'react-hotkeys';
import { white } from '../utils/colors';

interface LightboxEntry {
  photo: IGatsbyImageData;
  title?: string;
}

interface LightboxProps {
  entries: LightboxEntry[];
  onClose: () => void;
  isOpen: boolean;
  startIndex?: number;
}

const transitionTime = 0.3;
const transitionPauseTime = 0.2;

export default ({ entries, onClose, isOpen, startIndex }: LightboxProps) => {
  const [index, setIndex] = useState(startIndex || 0);
  const [transitioning, setTransitioning] = useState(false);
  const [transitionType, setTransitionType] = useState('');
  const [closing, setClosing] = useState(false);

  const modIndex = (i: number): number => {
    const m = entries.length;
    return ((i % m) + m) % m;
  };

  const transitionChange = (diff: number) => {
    setTransitionType(diff > 0 ? 'next' : 'prev');
    setTransitioning(true);

    // change index after css animation
    setTimeout(() => {
      setIndex((i) => modIndex(i + diff));
    }, transitionTime * 1000);
  };

  // finish transition when index is changed during transition
  useEffect(() => {
    if (transitioning)
      setTimeout(
        () => setTransitioning(false),
        transitionTime * 1000 + transitionPauseTime * 1000,
      );
  }, [index, transitioning]);

  const handlers = {
    NEXT: () => transitionChange(1),
    PREV: () => transitionChange(-1),
    CLOSE: () => setClosing(true),
    PREVENTSCROLL: (event: KeyboardEvent | undefined) =>
      event && event.preventDefault(),
  };

  const swipeHandlers = {
    onSwipedLeft: () => handlers.NEXT(),
    onSwipedRight: () => handlers.PREV(),
    onSwipedUp: () => handlers.CLOSE(),
    onSwipedDown: () => handlers.CLOSE(),
  };

  const { title, photo } = entries[index];
  const { photo: nextPhoto } = entries[modIndex(index + 1)];
  const { photo: prevPhoto } = entries[modIndex(index - 1)];

  return (
    <HotKeys
      handlers={handlers}
      keyMap={{
        NEXT: 'right',
        PREV: 'left',
        PREVENTSCROLL: ['down', 'up'],
      }}
    >
      <StyledDialogOverlay isOpen={isOpen} onDismiss={handlers.CLOSE}>
        <CSSTransition
          in={!closing}
          timeout={transitionTime * 1000}
          classNames="closing"
          enter={false}
          onExited={() => {
            onClose();
            setClosing(false);
          }}
        >
          <StyledDialogContent>
            <CloseControl>
              <button
                style={{ width: 0, height: 0, opacity: 0 }}
                type="button"
                aria-hidden
                aria-label="hidden"
              />
              <Button
                icon={<Close color={white} />}
                onClick={handlers.CLOSE}
                label="Schließen"
                a11yTitle="Galerie schließen"
                plain
                reverse
              />
            </CloseControl>
            <PrevControl>
              <Button
                icon={<Previous color={white} />}
                onClick={handlers.PREV}
                a11yTitle="vorheriges Bild"
                plain
              />
            </PrevControl>

            <NextControl>
              <Button
                icon={<Next color={white} />}
                onClick={handlers.NEXT}
                a11yTitle="nächstes Bild"
                plain
              />
            </NextControl>
            <CaptionControl>{title && <span>{title}</span>}</CaptionControl>
            <CSSTransition
              in={!transitioning}
              timeout={transitionTime * 1000}
              classNames="img-slide"
            >
              <ImgSlider className={[transitionType, 'lightbox-img'].join(' ')}>
                <StyledSwipeable
                  onSwipedLeft={swipeHandlers.onSwipedLeft}
                  onSwipedRight={swipeHandlers.onSwipedRight}
                  onSwipedUp={swipeHandlers.onSwipedUp}
                  onSwipedDown={swipeHandlers.onSwipedDown}
                  delta={40}
                  trackMouse
                  preventDefaultTouchmoveEvent
                >
                  <ImgContained
                    image={photo}
                    objectFit="contain"
                    loading="eager"
                    alt={title ?? ''}
                  />
                </StyledSwipeable>
              </ImgSlider>
            </CSSTransition>
            <PreloadImg photo={prevPhoto} />
            <PreloadImg photo={nextPhoto} />
          </StyledDialogContent>
        </CSSTransition>
      </StyledDialogOverlay>
    </HotKeys>
  );
};

const ImgSlider = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translateX(0);
  transition-duration: ${transitionTime}s;
  transition-property: transform, opacity;

  &.img-slide-exit-active {
    opacity: 0;
    transition-timing-function: ease-in;
  }
  &.img-slide-exit-done {
    opacity: 0;
    transition: none !important;
  }
  /* styles while old image slides out screen */
  &.img-slide-enter {
    opacity: 0;
    transition: none;
  }
  &.img-slide-enter-active {
    opacity: 1 !important;
    transform: translateX(0) !important;
    transition-duration: ${transitionTime}s !important;
    transition-property: transform, opacity !important;
    transition-timing-function: ease-out !important;
  }
  /* styles while new image slides into screen  */
  &.img-slide-exit-active.next,
  &.img-slide-exit-done.prev,
  &.img-slide-enter.prev {
    transform: translateX(-400px);
  }

  &.img-slide-exit-active.prev,
  &.img-slide-exit-done.next,
  &.img-slide-enter.next {
    transform: translateX(400px);
  }
`;

const ImgContained = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const controlCss = css`
  position: fixed;
  z-index: 2;
  padding: 3px;
  color: ${white};
`;

const PrevControl = styled.div`
  top: 50%;
  left: 0;
  width: 30px;
  ${controlCss}
`;

const NextControl = styled.div`
  top: 50%;
  right: 0;
  display: flex;
  width: 30px;
  flex-direction: row-reverse;
  ${controlCss}
`;

const CloseControl = styled.div`
  top: 0;
  right: 0;
  display: flex;
  height: 30px;
  flex-direction: row;
  justify-content: end;
  ${controlCss}
`;

const CaptionControl = styled.div`
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  ${controlCss}
  text-shadow: 1px 1px 2px black;
`;

const StyledSwipeable = styled(Swipeable)`
  width: 100%;
  height: 100%;
`;

const StyledDialogOverlay = styled(DialogOverlay)`
  z-index: 2;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0;
  background: hsla(0, 0%, 0%, 0.9);
`;

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  padding: 30px !important;
  margin: auto !important;
  background: none !important;

  /* closing animation  */
  opacity: 1;
  transition-duration: ${transitionTime}s;
  transition-property: opacity;
  transition-timing-function: ease-in;

  &.closing-exit {
    opacity: 0;
  }
  &.closing-exit .lightbox-img {
    transform: scale(0.1);
  }
`;

const StyledImg = styled.img`
  display: none;
`;

const PreloadImg = ({ photo }: { photo: IGatsbyImageData }) => {
  const source = photo?.images?.sources?.[0];
  if (!source) return null;
  return <StyledImg srcSet={source.srcSet} sizes={source.sizes} alt="" />;
};
