import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import { Sculpture, ImageGallery } from 'schema-dts';
import Layout from '../components/Layout/Layout';
import abihirschMetas from '../data/abihirsche';
import Lightbox from '../components/Lightbox';
import JsonLd from '../utils/json-ld';
import Timeline from '../components/Timeline';

export const frontmatter = {
  title: 'Abihirsche',
  menu: 5,
};

interface ImgList {
  edges: {
    node: ImgNode;
  }[];
}

interface ImgNode {
  name: string;
  childImageSharp: {
    fixedSmall: IGatsbyImageData;
    fluid: IGatsbyImageData;
  };
}

export default () => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const { abihirschPhotos }: { abihirschPhotos: ImgList } =
    useStaticQuery(graphql`
      {
        abihirschPhotos: allFile(
          filter: { dir: { regex: "/images/abihirsche$/" } }
          sort: { fields: name, order: DESC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid: gatsbyImageData(layout: FULL_WIDTH)
                fixedSmall: gatsbyImageData(height: 400, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    `);

  const abihirschPhotosIndexed = abihirschPhotos.edges.map(
    ({ node }, index) => ({
      ...node,
      index,
    }),
  );

  const entries = abihirschMetas.map((meta) => {
    const photos = abihirschPhotosIndexed.filter((node) =>
      node.name.match(meta.matchingFiles),
    );
    return { ...meta, photos };
  });

  const lightboxEntries = abihirschPhotos.edges.map(({ node }) => {
    const meta = abihirschMetas.find(
      (metaEle) => !!node.name.match(metaEle.matchingFiles),
    );
    let title = '';
    if (meta) {
      title += meta.title;
      if (meta.subtitle) title += ` - ${meta.subtitle}`;
    }

    return {
      photo: node.childImageSharp.fluid,
      title,
    };
  });

  const jsonLdEntries: Sculpture[] = entries.map((entry) => ({
    '@type': 'Sculpture',
    headline: `${entry.title} - ${entry.subtitle}`,
    dateCreated: entry.title,
    encodings: entry.photos.map((photo) => ({
      '@type': 'ImageObject',
      url: photo.childImageSharp.fixedSmall.images.fallback?.src,
    })),
  }));

  const openLightboxAt = (i: number) => {
    setLightboxIndex(i);
    setShowLightbox(true);
  };

  return (
    <>
      <Layout>
        <Helmet>
          <title>Abihirsche</title>
        </Helmet>
        <JsonLd<ImageGallery>>
          {{
            '@context': 'https://schema.org',
            '@type': 'ImageGallery',
            about: 'Abihirsche',
            hasPart: jsonLdEntries,
          }}
        </JsonLd>

        <h1>Die Abihirsche</h1>
        <p>
          Der Abihirsch des Jahrgangs 1953 trägt das Motto „Sportabitur“. Nach
          bestandenem Abitur und während der diversen Abi-Feten der
          Alten-Landes-Schüler werden in jedem Jahr neue Pläne geschmiedet, das
          Wappentier der Hirsch-Apotheke als Zeichen der neu erworbenen Reife
          „umzugestalten“.
        </p>
        <p>
          Ob als Oscar-Gewinner, Weltmeister oder ebay-Paket, stets ersinnen die
          Ex-Pennäler mit Humor und Kreativität eine aktuelle Verkleidung, um
          das Ende ihrer Schulzeit zu feiern. Seit vielen Jahrzehnten wird
          dieser Brauch von den Korbacher Abiturienten der Alten Landesschule
          mit Hingabe gepflegt.
        </p>
        <p>
          Schon in den 50er Jahren versuchte der damalige Stadtarchivar Wilhelm
          Hellwig, die Ursprünge dieser Tradition zu ergründen. In seinem
          Aufsatz „Der blaue Hirsch“ berichtete Hellwig in der Zeitschrift
          „Klosterglöckchen“ von der Herkunft des Brauchs aus Marburg, wo eine
          Studentenverbindung den Schwan der gleichnamigen Apotheke nach dem
          jährlichen Kommersabend mit blauer Farbe anmalte. Wann das die
          Korbacher Abiturienten zum ersten Mal nachmachten, fand er aber schon
          damals nicht mehr heraus.
        </p>
        <p>
          Eine ganze Reihe der Abihirsche sind im Bild festgehalten worden und
          hier zu sehen. Die meisten davon wurden uns von netten Kunden zur
          Verfügung gestellt, bei denen ich mich an dieser Stelle noch einmal
          ganz herzlich bedanken möchte. Sollten Sie noch das Bild eines
          Abihirschen besitzen, der noch nicht hier gezeigt wurde, dann melden
          Sie sich bitte in der Hirsch-Apotheke. Ein kleines Präsent wartet auf
          Sie.
        </p>
        <h2>Galerie der Abihirsche</h2>
        <p>
          In dieser Galerie sind eine Vielzahl der verschiedenen Abihirsche
          festgehalten. Sie beginnt mit den aktuellesten Aufnahmen und geht
          zurück bis in die 50er Jahre.
          <br />
          Bewegen Sie die Leiste nach rechts um weitere Bilder anzuzeigen und
          klicken Sie auf eines der Fotos um es im Vollbild anzuzeigen.
        </p>
        <Timeline>
          {entries.map((entry) => (
            <li key={entry.title}>
              <YearTitle>
                {entry.title}
                {entry.subtitle ? ` - ${entry.subtitle}` : null}
              </YearTitle>
              <PhotosContainer>
                {entry.photos.map((photo) => (
                  <AbiPhoto
                    onClick={() => openLightboxAt(photo.index)}
                    key={photo.name}
                    type="button"
                    aria-label={`Foto Nr. ${
                      photo.index + 1
                    } vergrößert anzeigen`}
                  >
                    <GatsbyImage
                      image={photo.childImageSharp.fixedSmall}
                      alt=""
                      style={{ flexShrink: 0 }}
                    />
                  </AbiPhoto>
                ))}
              </PhotosContainer>
            </li>
          ))}
        </Timeline>
      </Layout>
      <Lightbox
        isOpen={showLightbox}
        entries={lightboxEntries}
        startIndex={lightboxIndex}
        onClose={() => setShowLightbox(false)}
        key={lightboxIndex}
      />
    </>
  );
};

const YearTitle = styled.h3`
  text-align: left;
  white-space: nowrap;
`;

const PhotosContainer = styled.div`
  display: flex;
  overflow: auto;
  max-width: 100%;
  flex-direction: row;
`;

const ResetStyleButton = styled.button`
  /* reset button appearance */
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
`;

const AbiPhoto = styled(ResetStyleButton)`
  flex-shrink: 0;
  margin: 0 3px;
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;
